import { Empty, Pagination, TablePaginationConfig } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Donor } from '../slices/donor';
import { DonorCard } from './cards/donorCard';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    margin: '0px 64px',
  },
  flexWrapper: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: '20px',
    rowGap: '0px',
  },

  flexItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pagination: {
    paddingBottom: '4dvh',
  },
});

interface DonorCardsProps {
  donors: Donor[] | null;
  onTableChange: (pagination: TablePaginationConfig) => void;
  loading: boolean;
  pagination?: false | TablePaginationConfig | undefined;
  financingNeedId?: string;
  redirectNewPage?: boolean;
}

export const DonorCards = ({
  donors,
  onTableChange,
  loading,
  pagination,
  financingNeedId,
  redirectNewPage = false,
}: DonorCardsProps) => {
  const classes = useStyles();
  console.log(donors);

  return (
    <>
      {!loading && donors?.length === 0 && (
        <Empty description={`Aucun résultat pour le moment...`} />
      )}
      {!loading && (
        <div className={classes.container}>
          <div className={classes.flexWrapper}>
            {donors?.map((donor) => (
              <div key={donor._id} className={classes.flexItem}>
                <DonorCard
                  donor={donor}
                  financingNeedId={financingNeedId}
                  redirectNewPage={redirectNewPage}
                />
              </div>
            ))}
          </div>
          {pagination && (
            <Pagination
              {...pagination}
              className={classes.pagination}
              onChange={(page) => onTableChange({ current: page })}
            />
          )}
        </div>
      )}
    </>
  );
};
