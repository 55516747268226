import { Button, Typography, Dropdown, Menu, Tooltip, message } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SubButton from '../../components/buttons/SubButton';
import { TagList } from '../../components/TagList';
import { theme } from '../../constants/theme';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { Donor, publishDonors, updateDonor } from '../../slices/donor';
import ReadMore from '../../components/ReadMore';
import { Classification } from 'src/slices/classification';
import { User } from 'src/slices/auth';
import {
  FileOutlined,
  LinkedinFilled,
  PlusCircleFilled,
  MinusCircleFilled,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { CallForTender } from 'src/slices/callForTender';
import { CallForTenderCards } from 'src/containers/CallForTenderCards';
import { FinancingNeed } from '../../slices/financingNeed';
import {
  addFollowedDonor,
  getNgo,
  removeFollowedDonor,
} from '../../slices/ngo';
import { isOrganizationPremium } from '../../utils/projects';
import { ensureHttps } from '../../utils/string.utils';

const { Title, Text, Link } = Typography;

const useStyles = createUseStyles({
  title: {
    width: '60%',
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '50px !important',
    lineHeight: '52.5px !important',
    marginBottom: '30px',
    marginTop: '0 !important',
    color: '#292929 !important',
  },
  dropdownContainer: {
    position: 'absolute',
    right: '20px',
    top: '45px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '0',
  },
  modifyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '4.5px',
    cursor: 'pointer',
  },
  modify: {
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '25px',
  },
  moreOutlined: {
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    cursor: 'default',
  },
  firstLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  fiche: {
    fontFamily: 'PP Mori',
    fontWeight: '600 !important',
    fontSize: '28px !important',
    lineHeight: '34px !important',
    letterSpacing: '-2% !important',
    marginBottom: '20px',
    color: '#5050F9 !important',
  },
  subtitle: {
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '23px',
    lineHeight: '28px',
    letterSpacing: '-2%',
    color: theme.palette.primary,
    marginBottom: '10px',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 20px',
    background: 'white',
    borderRadius: '10px',
    width: '869px',
    height: 'fit-content',
    position: 'relative',
  },
  titleContainer: {
    marginBottom: '30px',
  },
  validateButton: {
    margin: '0 0.5vw 0.5vw 0',
    padding: '0.25vw 0.9375vw',
    fontSize: 'calc(5px + 0.4vw)',
    height: 'fit-content',
  },
  description: {
    fontSize: '0.75vw',
  },
  displayLineBreak: {
    whiteSpace: 'pre-line',
    marginBottom: '3.716%',
    textJustify: 'auto',
    textAlign: 'justify',
    wordBreak: 'break-word',
    hyphens: 'auto',
    fontSize: '0.875vw',
    ['@media screen and (max-width: 1200px)']: {
      fontSize: '9px',
    },
  },
  fileContainer: {
    display: 'flex',
  },
  filesDisplay: {
    margin: '0 0 0 0.3125vw',
  },
  validateCallForTender: {
    fontSize: 'calc(5px + 0.35vw)',
    marginLeft: '0.3vw',
    lineHeight: '1vw',
  },
  firstContainer: {
    height: '37px',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    '& .anticon-heart': {
      fontSize: 'calc(8px + 0.5vw)',
      margin: '0em 0 0.5em 1.8%',
      color: theme.palette.primary,
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    height: '16px',
    '& a.ant-typography, .ant-typography a': {
      height: '16px !important',
    },
  },
  linkIcon: {
    color: theme.palette.primary,
    fontSize: '30px',
  },
  donorLink: {
    fontSize: 12,
    color: '#5050F6 !important',
    textDecoration: 'underline !important',
    '&:hover': {
      color: 'green !important',
    },
  },
});

interface Props {
  displayedDonor: Donor;
  classification: Classification;
  donorId: string;
  user: User;
  callForTenders: CallForTender[];
  financingNeed?: FinancingNeed;
  financingNeeds?: FinancingNeed[];
  onAddDonor: (donorId: string, financingNeedId: string) => void;
  onRemoveDonor: (donorId: string, financingNeedId: string) => void;
}

const DonorPageLeftColumn: React.VFC<Props> = ({
  displayedDonor,
  classification,
  donorId,
  user,
  callForTenders,
  financingNeed,
  financingNeeds,
  onAddDonor,
  onRemoveDonor,
}) => {
  const dispatch = useAppDispatch();
  const { ngo } = useAppSelector(({ ngo: { ngo } }) => ({
    ngo,
  }));

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getNgo(user.ngoId));
    }
  }, []);

  console.log('ngo', ngo);
  const classes = useStyles();
  const history = useHistory();

  const { tags } = classification;

  const currentDate = new Date();
  const [
    activeCallForTenders,
    missingCallForTenders,
    inactiveCallForTenders,
    futureCallForTenders,
  ] = callForTenders.reduce<
    [
      Array<CallForTender>,
      Array<CallForTender>,
      Array<CallForTender>,
      Array<CallForTender>,
    ]
  >(
    (result, tender) => {
      const openingDate = new Date(
        tender?.openingDate ? tender.openingDate : '',
      );
      const closingDate = new Date(
        tender?.closingDate ? tender.closingDate : '',
      );
      if (openingDate > currentDate) {
        result[3].push(tender);
      } else if (closingDate < currentDate) {
        result[2].push(tender);
      } else if (closingDate > currentDate) {
        result[0].push(tender);
      } else if (
        tender?.closingDate === null ||
        tender?.closingDate === undefined
      ) {
        result[1].push(tender);
      }
      return result;
    },
    [[], [], [], []],
  );

  const getActiveSorted = (callForTendersArray: CallForTender[]) => {
    callForTendersArray.sort((a, b) => {
      const aClosingDate = new Date(a?.closingDate ? a.closingDate : '');
      const bClosingDate = new Date(b?.closingDate ? b.closingDate : '');
      if (!a.closingDate || !b.closingDate) {
        return 0;
      }
      return aClosingDate.getTime() - bClosingDate.getTime();
    });
    return callForTendersArray;
  };

  const getInactiveSorted = (callForTendersArray: CallForTender[]) => {
    callForTendersArray.sort((a, b) => {
      const aClosingDate = new Date(a?.closingDate ? a.closingDate : '');
      const bClosingDate = new Date(b?.closingDate ? b.closingDate : '');
      if (!a.closingDate || !b.closingDate) {
        return 0;
      }
      return bClosingDate.getTime() - aClosingDate.getTime();
    });
    return callForTendersArray;
  };

  const getFutureSorted = (callForTendersArray: CallForTender[]) => {
    callForTendersArray.sort((a, b) => {
      const aOpeningDate = new Date(a?.openingDate ? a.openingDate : '');
      const bOpeningDate = new Date(b?.openingDate ? b.openingDate : '');
      if (!a.openingDate || !b.openingDate) {
        return 0;
      }
      return aOpeningDate.getTime() - bOpeningDate.getTime();
    });
    return callForTendersArray;
  };

  const sortedActiveCallForTenders =
    activeCallForTenders.length === 0
      ? activeCallForTenders
      : getActiveSorted(activeCallForTenders);

  const sortedInactiveCallForTenders =
    inactiveCallForTenders.length === 0
      ? inactiveCallForTenders
      : getInactiveSorted(inactiveCallForTenders);

  const sortedFutureCallForTenders =
    futureCallForTenders.length === 0
      ? futureCallForTenders
      : getFutureSorted(futureCallForTenders);

  const handleValidation = (_id: string) => {
    dispatch(publishDonors({ _id: _id }));
    history.push(`/donorsToValidate`);
  };

  const handleQualify = (_id: string) => {
    dispatch(
      updateDonor({
        updateDonorDto: { _id: _id, publicationStatus: 'toValidate' },
      }),
    );

    history.replace('/');
  };

  const handleReverseValidation = (_id: string) => {
    dispatch(
      updateDonor({
        updateDonorDto: { _id: _id, publicationStatus: 'assigned' },
      }),
    );
    history.push(`/donorsToQualify`);
  };

  const handleNotRelevant = (_id: string) => {
    dispatch(
      updateDonor({
        updateDonorDto: { _id, publicationStatus: 'notRelevant' },
      }),
    );
    history.goBack();
  };

  const renderFiles = () => {
    return displayedDonor.fileUrls?.map((file, key) => {
      try {
        const array = file[0].split('||||$$$$%%%%&&&&');
        return (
          <div key={key} className={classes.fileContainer}>
            <Button
              href={array[0]}
              target="_blank"
              className={classes.filesDisplay}
            >
              <FileOutlined />
              {array[1]}
            </Button>
          </div>
        );
      } catch (e) {
        console.error(e);
        return <></>;
      }
    });
  };

  const renderLastCallForTenderValidation = () => {
    const date = displayedDonor?.lastCallForTenderValidation
      ? new Date(displayedDonor?.lastCallForTenderValidation)
      : undefined;
    return (
      displayedDonor?.lastCallForTenderValidation && (
        <div className={classes.validateCallForTender}>
          Dernière validation:
          <br />
          {new Intl.DateTimeFormat('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(date)}
        </div>
      )
    );
  };

  const renderIconLink = (Icon: React.ComponentType<any>, url: string) => {
    return (
      <Link className={classes.link} href={url} target="_blank">
        <Icon className={classes.linkIcon} />
      </Link>
    );
  };

  const renderIconClick = (
    Icon: React.ComponentType<any>,
    onClick?: () => void,
    color?: string,
  ) =>
    color ? (
      <span className={classes.link} onClick={onClick}>
        <Icon className={classes.linkIcon} style={{ color: color }} />
      </span>
    ) : (
      <span className={classes.link} onClick={onClick}>
        <Icon className={classes.linkIcon} />
      </span>
    );

  const renderFinancingNeedsList = (
    financingNeedsOptions: FinancingNeed[],
    donorId: string,
    add: boolean,
  ) => (
    <>
      {financingNeedsOptions.map((financingNeedOption, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            add
              ? onAddDonor(donorId, financingNeedOption._id)
              : onRemoveDonor(donorId, financingNeedOption._id);
          }}
        >
          {financingNeedOption.name}
        </Menu.Item>
      ))}
    </>
  );

  const renderAddDonorSubMenu = (
    donor: Donor,
    financingNeedsOptions: FinancingNeed[] | undefined,
  ) => {
    return (
      !!financingNeedsOptions?.length && (
        <SubMenu title={'Ajouter à un projet'}>
          {renderFinancingNeedsList(financingNeedsOptions, donor._id, true)}
        </SubMenu>
      )
    );
  };

  const rendeRemoveDonorSubMenu = (
    donor: Donor,
    financingNeedsOptions: FinancingNeed[] | undefined,
  ) => {
    return (
      !!financingNeedsOptions?.length && (
        <SubMenu title={"Retirer d'un projet"}>
          {renderFinancingNeedsList(financingNeedsOptions, donor._id, false)}
        </SubMenu>
      )
    );
  };

  const renderAddRemoveProject = () => {
    if (financingNeed) {
      return financingNeed?.donors.includes(displayedDonor._id) ||
        !financingNeeds?.some(
          (financingNeed) => !financingNeed.donors.includes(displayedDonor._id),
        ) ? (
        <SubButton
          onClick={() => onRemoveDonor(displayedDonor._id, financingNeed?._id)}
          variant={'unfavorite'}
        ></SubButton>
      ) : (
        <SubButton
          onClick={() => onAddDonor(displayedDonor._id, financingNeed?._id)}
          variant={'favorite'}
        ></SubButton>
      );
    } else {
      const financingNeedsIncludeDonor = financingNeeds?.filter(
        (financingNeed) => financingNeed.donors.includes(displayedDonor._id),
      );
      const financingNeedsDontIncludeDonor = financingNeeds?.filter(
        (financingNeed) => !financingNeed.donors.includes(displayedDonor._id),
      );
      const menu = (
        <Menu>
          {!!financingNeedsDontIncludeDonor?.length &&
            !financingNeedsIncludeDonor?.length &&
            renderFinancingNeedsList(
              financingNeedsDontIncludeDonor,
              displayedDonor._id,
              true,
            )}
          {!!financingNeedsIncludeDonor?.length &&
            !financingNeedsDontIncludeDonor?.length &&
            renderFinancingNeedsList(
              financingNeedsIncludeDonor,
              displayedDonor._id,
              false,
            )}
          {!!financingNeedsDontIncludeDonor?.length &&
            !!financingNeedsIncludeDonor?.length && (
              <>
                {renderAddDonorSubMenu(
                  displayedDonor,
                  financingNeedsDontIncludeDonor,
                )}
                {rendeRemoveDonorSubMenu(
                  displayedDonor,
                  financingNeedsIncludeDonor,
                )}
              </>
            )}
        </Menu>
      );
      return (
        <Dropdown overlay={menu}>
          {!financingNeeds?.some(
            (financingNeed) =>
              !financingNeed.donors.includes(displayedDonor._id),
          )
            ? renderIconClick(MinusCircleFilled, undefined, 'red')
            : renderIconClick(PlusCircleFilled)}
        </Dropdown>
      );
    }
  };

  const menuMoreOutlined = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push(`/donor/${donorId}/edit`);
        }}
        icon={<EditOutlined style={{ color: 'blue' }} />}
      >
        {'Modifier'}
      </Menu.Item>
    </Menu>
  );

  const onClickNewProject = () => {
    if (user?.role === 'admin') {
      history.push(`/donor/${displayedDonor?._id}/saveTenderDonor`);
    } else {
      if (
        displayedDonor?.callForTenders?.length &&
        user?.maxNumberOfProjects &&
        displayedDonor?.callForTenders?.length >= user.maxNumberOfProjects
      ) {
        message.error(
          user.maxNumberOfProjects === 1
            ? 'Vous ne pouvez pas créer plus de ' +
                user.maxNumberOfProjects +
                ' projet'
            : 'Vous ne pouvez pas créer plus de ' +
                user.maxNumberOfProjects +
                ' projets',
        );
      } else {
        if (user?.donorId) {
          history.push(`/donor/${user?.donorId}/saveTenderDonor`);
        }
      }
    }
  };

  return (
    <div className={classes.leftColumn}>
      <div className={classes.dropdownContainer}>
        {(displayedDonor._id === user.donorId || user.role === 'admin') && (
          <Dropdown
            overlay={menuMoreOutlined}
            placement={'bottomCenter'}
            trigger={['click']}
          >
            <div className={classes.modifyContainer}>
              <EditOutlined size={14} color={'black'} />
              <Text className={classes.modify}>Modifier</Text>
            </div>
          </Dropdown>
        )}
      </div>
      <Title className={classes.fiche}>Fiche Mécène</Title>
      <Title className={classes.title}>{displayedDonor.name}</Title>
      <div className={classes.titleContainer}>
        <div
          style={{
            marginBottom: 'auto',
            marginTop: 'auto',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
          }}
        >
          <div className={classes.firstContainer}>
            {(displayedDonor.linkedinUrl ||
              displayedDonor.companyLinkedinUrl) &&
              renderIconLink(
                LinkedinFilled,
                (displayedDonor.linkedinUrl ||
                  displayedDonor.companyLinkedinUrl) as string,
              )}
            {user.ngoId != undefined &&
              isOrganizationPremium(financingNeeds ?? []) && (
                <Tooltip
                  placement="top"
                  title={
                    <span>Ajouter / Supprimer de votre tableau de suivi</span>
                  }
                >
                  {renderAddRemoveProject()}
                </Tooltip>
              )}
            {user.ngoId != undefined &&
              ngo &&
              isOrganizationPremium(financingNeeds ?? []) &&
              (!ngo.followedDonors ||
                !ngo?.followedDonors.includes(donorId)) && (
                <SubButton
                  onClick={() =>
                    dispatch(addFollowedDonor({ donorId: displayedDonor._id }))
                  }
                  variant="subscribe"
                />
              )}
            {user.ngoId != undefined &&
              ngo &&
              ngo.followedDonors &&
              ngo?.followedDonors.includes(donorId) && (
                <SubButton
                  onClick={() =>
                    dispatch(
                      removeFollowedDonor({ donorId: displayedDonor._id }),
                    )
                  }
                  variant={'unsubscribe'}
                />
              )}
          </div>
          {user?.role === 'admin' && (
            <div style={{ display: 'flex', position: 'relative' }}>
              <Button
                className={classes.validateButton}
                onClick={onClickNewProject}
                type="dashed"
                icon={<PlusCircleOutlined />}
              >
                Ajouter un programme de soutien
              </Button>
              {user?.role === 'admin' && displayedDonor.usesCallsToTender && (
                <div>{renderLastCallForTenderValidation()}</div>
              )}
            </div>
          )}
          {(user.role === 'admin' || user.role === 'contributor') && (
            <div style={{ display: 'flex', position: 'relative' }}>
              {displayedDonor.publicationStatus === 'toValidate' &&
                user?.role === 'admin' && (
                  <Button
                    className={classes.validateButton}
                    type="primary"
                    onClick={() => handleValidation(donorId)}
                  >
                    Valider
                  </Button>
                )}
              {displayedDonor.publicationStatus === 'assigned' &&
                user?.role === 'contributor' && (
                  <>
                    <Button
                      className={classes.validateButton}
                      type="primary"
                      onClick={() => handleQualify(donorId)}
                    >
                      Envoyer à la validation
                    </Button>
                    <Button
                      className={classes.validateButton}
                      danger
                      type="primary"
                      onClick={() => handleNotRelevant(donorId)}
                    >
                      Non pertinent
                    </Button>
                  </>
                )}
              {(displayedDonor?.publicationStatus === 'toValidate' ||
                displayedDonor?.publicationStatus === 'published') &&
                user?.role === 'admin' && (
                  <Button
                    className={classes.validateButton}
                    type="primary"
                    onClick={() => handleReverseValidation(donorId)}
                  >
                    Renvoyer à qualifier
                  </Button>
                )}
            </div>
          )}
          {(displayedDonor.homepageUrl || displayedDonor.otherUrl) && (
            <a
              onClick={(e) => {
                e.stopPropagation();
                window.open(
                  ensureHttps(
                    displayedDonor.homepageUrl ?? displayedDonor.otherUrl ?? '',
                  ),
                  '_blank',
                );
              }}
              className={classes.donorLink}
            >
              {displayedDonor.homepageUrl ?? displayedDonor.otherUrl}
            </a>
          )}
          {displayedDonor.tags && (
            <TagList
              elementIds={displayedDonor.tags}
              collection={tags}
              color="default"
              collectionName="tags"
              highlightedIds={financingNeed?.tags ?? []}
              maxElements={12}
            />
          )}
        </div>
      </div>
      {displayedDonor.description && (
        <>
          <Text className={classes.subtitle}>Description</Text>
          <ReadMore
            description={displayedDonor.description}
            maximumNumberOfCharacters={1396}
          />
        </>
      )}
      {user?.role === 'admin' && displayedDonor.comments && (
        <>
          <Text className={classes.subtitle}>Commentaires</Text>
          <Text className={classes.displayLineBreak}>
            {displayedDonor.comments}
          </Text>
        </>
      )}
      {user?.role === 'admin' && displayedDonor?.fileUrls?.length !== 0 && (
        <>
          <Text className={classes.subtitle}>Fichiers</Text>
        </>
      )}
      {user?.role === 'admin' && displayedDonor?.fileUrls && renderFiles()}
      {(sortedActiveCallForTenders.filter((c) => c.published).length !== 0 ||
        missingCallForTenders.filter((c) => c.published).length !== 0) && (
        <>
          <Text className={classes.subtitle}>Programmes de soutien actifs</Text>
        </>
      )}
      {sortedActiveCallForTenders.filter((c) => c?.published).length !== 0 && (
        <CallForTenderCards
          donorId={donorId}
          callForTenders={sortedActiveCallForTenders.filter(
            (c) => c?.published,
          )}
          isActive={true}
        />
      )}
      {missingCallForTenders.filter((c) => c?.published).length !== 0 && (
        <CallForTenderCards
          donorId={donorId}
          callForTenders={missingCallForTenders.filter((c) => c?.published)}
          isActive={true}
        />
      )}
      {/*{(user?.role === 'admin' || user?.donorId === donorId) &&*/}
      {/*  callForTenders.length !== 0 && (*/}
      {/*    <CallForTenderCards*/}
      {/*      donorId={donorId}*/}
      {/*      callForTenders={callForTenders}*/}
      {/*    />*/}
      {/*  )}*/}
      {sortedInactiveCallForTenders.filter((c) => c.published).length !== 0 && (
        <>
          <Text className={classes.subtitle}>
            Programmes de soutien inactifs
          </Text>
        </>
      )}
      {sortedInactiveCallForTenders.filter((c) => c.published).length !== 0 && (
        <CallForTenderCards
          donorId={donorId}
          callForTenders={sortedInactiveCallForTenders.filter(
            (c) => c.published,
          )}
          isActive={false}
        />
      )}
      {sortedFutureCallForTenders.filter((c) => c.published).length !== 0 && (
        <>
          <Text className={classes.subtitle}>
            Programmes de soutien à venir
          </Text>
        </>
      )}
      {sortedFutureCallForTenders.filter((c) => c.published).length !== 0 && (
        <CallForTenderCards
          donorId={donorId}
          callForTenders={sortedFutureCallForTenders.filter((c) => c.published)}
          isActive={false}
        />
      )}
    </div>
  );
};

export default DonorPageLeftColumn;
