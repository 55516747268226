export function capitalizeWords(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function ensureHttps(url: string): string {
  if (!url) return url;

  // Check if URL already has a protocol
  if (url.match(/^https?:\/\//i)) {
    return url;
  }

  // Add https:// to the beginning
  return `https://${url}`;
}
