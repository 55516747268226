import { Button, message, Switch, Typography } from 'antd';
import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { fetchCurrentUser } from 'src/slices/auth';
import { LoggedIntercom } from 'src/utils/loggedIntercom';
import { theme } from '../constants/theme';
import { apiRequest } from '../helpers/api';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { getFinancingNeeds } from '../slices/financingNeed';
import { getNgo } from '../slices/ngo';
import { OfferModal } from 'src/components/OfferModal';
import { checkHasPaidOffer, isOrganizationPremium } from '../utils/projects';
import { formatDate } from '../utils/date.utils';
import { TagList } from '../components/TagList';
import { useSortedClassification } from '../hooks/useSortedClassification';
import { FullscreenSpin } from '../components/FullscreenSpin';
import FavButton from '../components/buttons/FavButton';

const { Text } = Typography;

function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref: any = useRef<T | null>(null);
  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);
  useEffect(() => {
    const node: any = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);
  return [ref, value];
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    maxWidth: '1208px', //1086+2* horizontal padding
    minHeight: '100%',
    display: 'flex',
    padding: '89px 61px',
    flexDirection: 'column',
  },
  projectHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  cardTitle: {
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '29px',
    marginBottom: '5px',
  },
  cardSubTitle: {
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '100%',
    color: theme.palette.primary,
  },
  title: {
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '36px',
    lineHeight: '100%',
    margin: '0 0 4.76% 0 !important',
  },
  dashboardTitle: {
    fontSize: '0.98vw',
    fontWeight: 'bold',
  },
  projectsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  projectContainer: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '20px',
    '&:hover': {
      backgroundColor: '#E8EDFC',
      // box-shadow: 0px 8px 16px 0px #0000001A;
      boxShadow: '0px 8px 16px 0px #0000001A',
    },
  },
  headerContainer: {
    marginBottom: '60px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '10px',
  },
  subtitle: {
    fontFamily: 'PP Mori',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '100%',
  },
  bannerImage: {
    width: '16.875vw',
    height: '7.49vw',
    borderTopRightRadius: '0.3125vw !important',
    borderTopLeftRadius: '0.3125vw !important',
  },
  button: {
    height: '4vw',
    width: '19.98%',
    backgroundColor: theme.palette.primary,
    borderColor: 'white',
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    borderRadius: '1vw',
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary,
      border: `1px solid ${theme.palette.primary}`,
    },
  },
  cardButton: {
    backgroundColor: '#fcfcfc',
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: '0.4vw',
    color: theme.palette.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary,
      border: `1px solid ${theme.palette.primary}`,
      color: 'white',
    },
  },
  cardButtonUnsubscribe: {
    marginTop: '0.2vw',
    backgroundColor: '#fcfcfc',
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: '0 0 0.4vw 0.4vw',
    color: theme.palette.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary,
      border: `1px solid ${theme.palette.primary}`,
      color: 'white',
    },
  },
  visibilityButton: {
    fontSize: '0.6vw',
    textAlign: 'center',
    fontWeight: 'normal',
    marginTop: '0.4vw',
    backgroundColor: theme.palette.primary,
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: '0 0 0.4vw 0.4vw',
    color: 'white',
    cursor: 'default',
  },
  nationaleButton: {
    width: '209px',
    height: 45,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    borderRadius: 10,
    borderWidth: 0.6,
    border: 'solid',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'PP Mori',
    lineHeight: '25px',
    cursor: 'pointer',
    borderColor: theme.palette.primary,
    backgroundColor: theme.palette.primary,
    color: 'white',
  },
  textButton: {
    color: theme.palette.primary,
    '&:hover': {
      color: 'white',
    },
  },
  cardMeta: {
    '& .ant-card-head-title': {
      whiteSpace: 'break-spaces',
      width: '100%',
    },
    '& .ant-card-head-wrapper': {
      flexDirection: 'column',
      overflow: 'hidden',
    },
    '& .ant-card-extra': {
      marginLeft: '0',
    },
    '& .ant-card-meta-description': {
      color: 'black !important',
      lineHeight: 1,
    },
    '& .ant-card-meta-title': {
      fontFamily: "'Baloo 2'",
      fontWeight: '600',
      fontSize: '1vw',
      lineHeight: 1,
      marginBottom: '0.9375vw !important',
    },
  },
  buttonDonor: {
    color: theme.palette.primary,
    fontWeight: 'bold',
    paddingBottom: '2%',
    '& .ant-btn': {
      width: '100%',
      fontSize: '0.6875vw',
      ['@media screen and (max-width: 1000px)']: {
        fontSize: '7.5px',
      },
      whiteSpace: 'normal',
      wordBreak: 'break-all',
      height: '100%',
      padding: '2.14% 8.02%',
    },
  },
  expirationDate: {
    color: theme.palette.primary,
    fontSize: '0.6125vw !important',
  },
  expirationDateAlt: {
    color: '#000',
    fontSize: '0.6125vw !important',
  },
  offer: {
    fontSize: '0.6125vw !important',
  },
  offerVisibility: {
    fontSize: '0.6125vw !important',
    color: 'black',
  },
  offerComplete: {
    fontSize: '0.6125vw !important',
    color: 'green',
    width: '100%',
  },
  expirationDateText: {
    fontSize: '0.6125vw !important',
  },
  buttonNational: {
    minHeight: '4vw',
    background:
      'linear-gradient(to right, rgba(0, 0, 255, 0.2) 0%, rgba(0, 0, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 66.66%, rgba(255, 0, 0, 0.2) 66.66%)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    color: '#000',
    '&:hover': {
      background:
        'linear-gradient(to right, rgba(0, 0, 255, 0.3) 0%, rgba(0, 0, 255, 0.3) 33.33%, rgba(255, 255, 255, 0.3)' +
        ' 33.33%, rgba(255, 255, 255, 0.3) 66.66%, rgba(255, 0, 0, 0.3) 66.66%)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
    },
  },
  buttonNationalSettings: {
    background:
      'linear-gradient(to right, rgba(0, 0, 255, 0.2) 0%, rgba(0, 0, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 66.66%, rgba(255, 0, 0, 0.2) 66.66%)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    color: '#000',
    '&:hover': {
      background:
        'linear-gradient(to right, rgba(0, 0, 255, 0.3) 0%, rgba(0, 0, 255, 0.3) 33.33%, rgba(255, 255, 255, 0.3)' +
        ' 33.33%, rgba(255, 255, 255, 0.3) 66.66%, rgba(255, 0, 0, 0.3) 66.66%)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
    },
    borderTop: '0px',
  },
  nationalTitle: {
    fontSize: '0.92vw',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bannerContainer: {
    marginTop: '2.5vw',
    backgroundColor: theme.palette.primary,
    padding: '2.5vw 2.5vw 2.5vw 2.5vw',
    borderRadius: '1vw',
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  bannerInfos: {
    display: 'flex',
    flexDirection: 'column',
  },
  bannerTitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '2vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
    color: 'white',
  },
  bannerSubtitle: {
    fontSize: '1vw',
    color: 'white',
  },
  bannerButton: {
    width: '253px',
    height: '55px',
    fontFamily: 'PP Mori',
    fontSize: '16px',
    lineHeight: '25px',
    color: 'white',
    backgroundColor: theme.palette.primary,
    borderColor: theme.palette.primary,
    position: 'relative',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary,
      color: 'white',
      border: theme.palette.primary,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  switch: {
    '& .ant-switch-checked': {
      backgroundColor: theme.palette.primary,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
});

interface Props {
  setIsNavBarHidden?: (isHidden: boolean) => void;
}

export const Projects: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [hoverRef] = useHover<HTMLDivElement>();
  const { user, users } = useAppSelector(({ auth: { user, users } }) => ({
    user,
    users,
  }));
  const [showOfferModal, setShowOfferModal] = useState(false);

  const { financingNeeds } = useAppSelector(
    ({ financingNeed: { financingNeeds } }) => ({
      financingNeeds,
    }),
  );
  const classification = useSortedClassification();

  useEffect(() => {
    if (setIsNavBarHidden) {
      setIsNavBarHidden(false);
    }
  }, []);

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getNgo(user.ngoId));
    }
  }, [user?.ngoId]);

  useEffect(() => {
    dispatch(getFinancingNeeds(user?.ngoId));
  }, []);

  useEffect(() => {
    fetchCurrentUser();
  }, [users]);

  const onClickNewProject = () => {
    if (financingNeeds.allIds.length + 1 > 10) {
      message.error('Vous ne pouvez pas créer plus de 10 projets.');
    } else {
      history.push('/newProject');
    }
  };

  const isPremium = useMemo(() => {
    return isOrganizationPremium(Object.values(financingNeeds.byId));
  }, [financingNeeds]);

  if (!classification) return <FullscreenSpin />;

  function renderFinancingNeeds() {
    return (
      <div className={classes.projectsContainer}>
        {Object.values(financingNeeds.byId).map((financingNeed) => (
          <div
            key={financingNeed._id}
            className={classes.projectContainer}
            onClick={() => {
              history.push(`/projects/${financingNeed._id}`);
            }}
          >
            <div style={{}} className={classes.projectHeader}>
              <div>
                <div className={classes.cardTitle}>{financingNeed.name}</div>
                <div className={classes.cardSubTitle}>
                  Créé le {formatDate(financingNeed.createdAt)}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  gap: '10px',
                  alignItems: 'end',
                  width: '275px',
                }}
              >
                <div
                  style={{
                    fontFamily: 'PP Mori',
                    fontWeight: '400px',
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                >
                  {financingNeed.published
                    ? 'Projet visible par les mécènes'
                    : 'Projet non visible par les mécènes'}
                </div>
                <div className={classes.switch}>
                  <Switch
                    checked={financingNeed.published}
                    onClick={async (status, e) => {
                      e.stopPropagation();
                      await apiRequest(
                        'PATCH',
                        `/financing-need/visibility/${financingNeed._id}`,
                        undefined,
                        {},
                      );
                      dispatch(getFinancingNeeds(user?.ngoId));
                    }}
                  />
                </div>
              </div>
            </div>
            {financingNeed.tags && (
              <TagList
                elementIds={financingNeed.tags}
                collection={classification.tags}
                color="default"
                collectionName="tags"
                maxElements={12}
              />
            )}
            <div>
              <div
                style={{
                  fontFamily: 'PP Mori',
                  fontSize: '20px',
                  lineHeight: '20px',
                  fontWeight: '600',
                  marginBottom: '10px',
                }}
              >
                Description
              </div>
              <div>{financingNeed.description}</div>
            </div>
            <div className={classes.buttons}>
              <div
                className={classes.nationaleButton}
                onClick={(event) => {
                  LoggedIntercom(
                    user?.firstName + ' ' + user?.lastName,
                    user?.email,
                    user?._id,
                    'ngoClickedSearchDonorsFrance',
                  );
                  event.stopPropagation();
                  if (isPremium) {
                    history.push(`/projects/${financingNeed._id}/database`);
                    return;
                  }
                  history.push(
                    `/freemium-projects/${financingNeed._id}/database/${isPremium}`,
                  );
                  return;
                }}
              >
                <div style={{ marginTop: '2px' }}>Rechercher des mécènes</div>
              </div>
              <FavButton
                onClick={(event) => {
                  event.stopPropagation();
                  if (isPremium) {
                    history.push(`/projects/${financingNeed._id}/donors`);
                    return;
                  }
                  history.push(
                    `/subscription/${financingNeed._id}/${isPremium}`,
                  );
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <div>
            <Text className={classes.title}>Bonjour {user?.firstName}</Text>
          </div>
          <div>
            <Text className={classes.subtitle}>
              Bienvenue sur votre tableau de bord
            </Text>
          </div>
        </div>
        <Button
          type="primary"
          onClick={onClickNewProject}
          className={classes.bannerButton}
          ref={hoverRef}
          style={{}}
        >
          Ajouter un nouveau projet
        </Button>
      </div>
      <div>
        {Object.values(financingNeeds.byId) &&
        Object.values(financingNeeds.byId).length === 0 ? (
          <div className={classes.bannerContainer}>
            <div className={classes.bannerContent}>
              <div className={classes.bannerInfos}>
                <div style={{ marginBottom: '1vw' }}>
                  <Text className={classes.bannerTitle}>
                    Afin de profiter au maximum de l’application Sonar, créez
                    votre premier projet.
                  </Text>
                </div>
                <div>
                  <Text className={classes.bannerSubtitle}>
                    N&apos;attendez plus pour accélérer vos recherches de
                    mécénat et
                    <br />
                    trouver les partenaires financiers idéaux pour votre
                    association.
                  </Text>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                }}
              >
                <Button
                  type="primary"
                  onClick={onClickNewProject}
                  className={classes.bannerButton}
                  style={{ width: 'auto' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '1vw',
                        lineHeight: 1,
                      }}
                    >
                      Ajouter un nouveau projet
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>{renderFinancingNeeds()}</>
        )}
      </div>
      <OfferModal
        isVisible={showOfferModal}
        requestClose={() => setShowOfferModal(false)}
        type="financingNeed"
        financingNeeds={Object.values(financingNeeds.byId).map(
          (financingNeed) => financingNeed,
        )}
        checkHasPaidOfferFN={checkHasPaidOffer}
      />
    </div>
  );
};
