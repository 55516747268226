import {
  EditOutlined,
  LinkedinFilled,
  MinusCircleFilled,
  PlusCircleFilled,
} from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Tooltip, Typography } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import React from 'react';
import { createUseStyles } from 'react-jss';
import '../../index.css';
import { useHistory } from 'react-router-dom';
import ReadMore from 'src/components/ReadMore';
import { Classification } from 'src/slices/classification';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { TagList } from '../../components/TagList';
import { Ngo } from 'src/slices/ngo';
import { theme } from '../../constants/theme';
import { ProjectCards } from '../../containers/ProjectCards';
import { User } from '../../slices/auth';
import { CallForTender } from '../../slices/callForTender';
import { FinancingNeed } from '../../slices/financingNeed';
import { ensureHttps } from '../../utils/string.utils';

const { Title, Text, Link } = Typography;

const useStyles = createUseStyles({
  title: {
    width: '60%',
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '50px !important',
    lineHeight: '52.5px !important',
    marginBottom: '30px',
    color: '#292929 !important',
  },
  dropdownContainer: {
    position: 'absolute',
    right: '20px',
    top: '45px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '0',
  },
  modifyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '4.5px',
    cursor: 'pointer',
  },
  modify: {
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '25px',
  },
  moreOutlined: {
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    cursor: 'default',
  },
  firstLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  fiche: {
    fontFamily: 'PP Mori',
    fontWeight: '600 !important',
    fontSize: '28px !important',
    lineHeight: '34px !important',
    letterSpacing: '-2% !important',
    marginBottom: '20px',
    color: '#5050F9 !important',
  },
  subtitle: {
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '23px',
    lineHeight: '28px',
    letterSpacing: '-2%',
    color: theme.palette.primary,
    marginBottom: '10px',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 20px',
    background: 'white',
    borderRadius: '10px',
    width: '869px',
    height: 'fit-content',
    position: 'relative',
  },
  titleContainer: {
    marginBottom: '30px',
  },
  validateButton: {
    margin: '0 0.5vw 0.5vw 0',
    padding: '0.25vw 0.9375vw',
    fontSize: 'calc(5px + 0.4vw)',
    height: 'fit-content',
  },
  tagsContainer: {
    marginTop: '30px',
  },
  logoImage: {
    width: '17%',
    maxHeight: 'calc(100% + 1.11vh)',
    position: 'relative',
    top: '-1.11vh',
    right: 0,
  },
  editButton: {
    margin: 0,
    fontSize: '0.875vw',
    height: '67.61%',
    padding: '0.25vw 0.9375vw',
  },
  displayLineBreak: {
    whiteSpace: 'pre-line',
    marginBottom: '3.716%',
    textJustify: 'auto',
    textAlign: 'justify',
    wordBreak: 'break-word',
    hyphens: 'auto',
    fontSize: '0.875vw',
    ['@media screen and (max-width: 1200px)']: {
      fontSize: '9px',
    },
  },
  firstContainer: {
    height: '37px',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    margin: '0 0 15px 0',
    alignItems: 'center',
    '& .anticon-heart': {
      fontSize: 'calc(8px + 0.5vw)',
      margin: '0em 0 0.5em 1.8%',
      color: theme.palette.primary,
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    height: '16px',
    '& a.ant-typography, .ant-typography a': {
      height: '16px !important',
    },
  },
  linkIcon: {
    color: theme.palette.primary,
    fontSize: '30px',
  },
  ngoLink: {
    fontSize: 12,
    color: '#5050F6 !important',
    textDecoration: 'underline !important',
    '&:hover': {
      color: 'green !important',
    },
  },
});

interface Props {
  classification: Classification;
  ngoId: string;
  ngo: Ngo;
  user: User;
  projects: FinancingNeed[];
  callForTender?: CallForTender;
  callForTenders?: CallForTender[];
  onAddNgo: (ngoId: string, callForTenderId: string) => void;
  onRemoveNgo: (ngoId: string, callForTenderId: string) => void;
}

const NgoPageLeftColumn: React.VFC<Props> = ({
  classification,
  ngoId,
  ngo,
  user,
  projects,
  callForTender,
  callForTenders,
  onAddNgo,
  onRemoveNgo,
}) => {
  const history = useHistory();
  const classes = useStyles();

  if (!classification || !ngo) return <FullscreenSpin />;

  const { tags } = classification;

  const renderIconLink = (Icon: React.ComponentType<any>, url: string) => {
    return (
      <Link className={classes.link} href={url} target="_blank">
        <Icon className={classes.linkIcon} />
      </Link>
    );
  };

  const renderIconClick = (
    Icon: React.ComponentType<any>,
    onClick?: () => void,
    color?: string,
  ) =>
    color ? (
      <span className={classes.link} onClick={onClick}>
        <Icon className={classes.linkIcon} style={{ color: color }} />
      </span>
    ) : (
      <span className={classes.link} onClick={onClick}>
        <Icon className={classes.linkIcon} />
      </span>
    );

  const renderCallForTendersList = (
    callForTendersOptions: CallForTender[],
    ngoId: string,
    add: boolean,
  ) => (
    <>
      {callForTendersOptions.map((cftOption, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            add
              ? onAddNgo(ngoId, cftOption._id)
              : onRemoveNgo(ngoId, cftOption._id);
          }}
        >
          {cftOption.name}
        </Menu.Item>
      ))}
    </>
  );

  const renderAddNgoSubMenu = (
    ngo: Ngo,
    callForTendersOptions: CallForTender[] | undefined,
  ) => {
    return (
      !!callForTendersOptions?.length && (
        <SubMenu title={'Ajouter à un projet'}>
          {renderCallForTendersList(callForTendersOptions, ngo._id, true)}
        </SubMenu>
      )
    );
  };

  const rendeRemoveNgoSubMenu = (
    ngo: Ngo,
    cftOptions: CallForTender[] | undefined,
  ) => {
    return (
      !!cftOptions?.length && (
        <SubMenu title={"Retirer d'un projet"}>
          {renderCallForTendersList(cftOptions, ngo._id, false)}
        </SubMenu>
      )
    );
  };

  const renderAddRemoveCallForTender = () => {
    if (callForTender) {
      return callForTender?.ngos.includes(ngo._id) ||
        !callForTenders?.some((cft) => !cft.ngos.includes(ngo._id))
        ? renderIconClick(
            MinusCircleFilled,
            () => onRemoveNgo(ngo._id, callForTender._id),
            'red',
          )
        : renderIconClick(PlusCircleFilled, () =>
            onAddNgo(ngo._id, callForTender._id),
          );
    } else {
      const callForTendersIncludeNgo = callForTenders?.filter((cft) =>
        cft.ngos.includes(ngo._id),
      );
      const callForTendersDontIncludeNgo = callForTenders?.filter(
        (cft) => !cft.ngos.includes(ngo._id),
      );
      const menu = (
        <Menu>
          {!!callForTendersDontIncludeNgo?.length &&
            !callForTendersIncludeNgo?.length &&
            renderCallForTendersList(
              callForTendersDontIncludeNgo,
              ngo._id,
              true,
            )}
          {!!callForTendersIncludeNgo?.length &&
            !callForTendersDontIncludeNgo?.length &&
            renderCallForTendersList(callForTendersIncludeNgo, ngo._id, false)}
          {!!callForTendersDontIncludeNgo?.length &&
            !!callForTendersIncludeNgo?.length && (
              <>
                {renderAddNgoSubMenu(ngo, callForTendersDontIncludeNgo)}
                {rendeRemoveNgoSubMenu(ngo, callForTendersIncludeNgo)}
              </>
            )}
        </Menu>
      );
      return (
        <Dropdown overlay={menu}>
          {!callForTenders?.some((cft) => !cft.ngos.includes(ngo._id))
            ? renderIconClick(MinusCircleFilled, undefined, 'red')
            : renderIconClick(PlusCircleFilled)}
        </Dropdown>
      );
    }
  };

  const menuMoreOutlined = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push(`/ngo/${ngoId}/edit`);
        }}
        icon={<EditOutlined style={{ color: 'blue' }} />}
      >
        {'Modifier'}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={classes.leftColumn}>
      <div className={classes.dropdownContainer}>
        {(ngo._id === user.ngoId || user.role === 'admin') && (
          <Dropdown
            overlay={menuMoreOutlined}
            placement={'bottomCenter'}
            trigger={['click']}
          >
            <div className={classes.modifyContainer}>
              <EditOutlined size={14} color={'black'} />
              <Text className={classes.modify}>Modifier</Text>
            </div>
          </Dropdown>
        )}
      </div>
      <Title className={classes.fiche}>Fiche porteur de projets</Title>
      <div className={classes.titleContainer}>
        <div style={{ marginBottom: 'auto', marginTop: 'auto', width: '100%' }}>
          <div className={classes.firstLine}>
            <Title className={classes.title} style={{ maxWidth: '80%' }}>
              {ngo.name}
            </Title>
          </div>

          <div className={classes.firstContainer}>
            {ngo.linkedinUrl &&
              renderIconLink(LinkedinFilled, ngo.linkedinUrl as string)}
            {user?.donorId && (
              <Tooltip
                placement="top"
                title={
                  <span>Ajouter / Supprimer de votre tableau de suivi</span>
                }
              >
                {renderAddRemoveCallForTender()}
              </Tooltip>
            )}
          </div>
          {ngo.websiteUrl && (
            <a
              onClick={(e) => {
                e.stopPropagation();
                window.open(ensureHttps(ngo.websiteUrl ?? ''), '_blank');
              }}
              className={classes.ngoLink}
            >
              {ngo.websiteUrl}
            </a>
          )}

          {/*{(ngo.admins.includes(user._id) || user.role === 'admin') && (*/}
          {/*  <Button*/}
          {/*    className={classes.editButton}*/}
          {/*    type="dashed"*/}
          {/*    onClick={() => history.push(`/ngo/${ngoId}/edit`)}*/}
          {/*    size="small"*/}
          {/*  >*/}
          {/*    Modifier*/}
          {/*  </Button>*/}
          {/*)}*/}
          {ngo.tags && (
            <div className={classes.tagsContainer}>
              <TagList
                elementIds={ngo.tags}
                collection={tags}
                color="default"
                collectionName="tags"
                highlightedIds={callForTender?.tags ?? []}
                maxElements={12}
              />
            </div>
          )}
        </div>
      </div>
      {ngo.bannerImageUrl && (
        <Avatar
          icon={<img src={ngo.bannerImageUrl} alt="banner" />}
          className={classes.logoImage}
        />
      )}
      {ngo.description && (
        <>
          <Text className={classes.subtitle}>Description</Text>
          <ReadMore
            description={ngo.description}
            maximumNumberOfCharacters={1267}
          />
        </>
      )}
      {/* {ngo.object && (
        <>
          <Text className={classes.subtitle}>Objet</Text>
          <Text className={classes.displayLineBreak}>{ngo.object}</Text>
        </>
      )} */}
      {(user?.role === 'admin' || user?.ngoId === ngoId) &&
        !!projects.length && (
          <>
            <Text className={classes.subtitle}>Projets</Text>
            <ProjectCards projects={projects} ngoName={ngo.name} />
          </>
        )}
      {!(user?.role === 'admin' || user?.ngoId === ngoId) &&
        !!projects.filter((c) => c.published).length && (
          <>
            <Text className={classes.subtitle}>Projets</Text>
            <ProjectCards
              projects={projects.filter((c) => c.published)}
              ngoName={ngo.name}
            />
          </>
        )}
    </div>
  );
};

export default NgoPageLeftColumn;
