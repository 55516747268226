import { HeartOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';

const useStyles = createUseStyles({
  button: {
    width: '169px',
    height: 45,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    borderRadius: 10,
    padding: '6px 20px',
    borderWidth: 0.6,
    border: 'solid',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'PP Mori',
    lineHeight: '25px',
    cursor: 'pointer',
  },
  buttonSub: {
    borderColor: theme.palette.primary,
    color: theme.palette.primary,
  },
  icon: {
    margin: '0 !important',
    fontSize: '16px',
  },
  iconSub: {
    color: theme.palette.primary,
  },
});

interface Props {
  onClick: (e: any) => void;
}

export default function FavButton({ onClick }: Props) {
  const classes = useStyles();
  return (
    <div
      className={clsx([classes.button, classes.buttonSub])}
      onClick={onClick}
    >
      <HeartOutlined className={clsx([classes.icon, classes.iconSub])} />
      <div style={{ marginTop: '2px' }}>Voir mes favoris</div>
    </div>
  );
}
